import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';

interface HeadingProps {
  heading: string;
}

export default function Heading({heading}: HeadingProps) {
  if (!heading) {
    return null;
  }

  return (
    <div className="container">
      <div className="md:w-3/4">
        <h2 className="text-t2 font-bold">{heading}</h2>
      </div>
      <Spacer size="3xl" />
    </div>
  );
}
