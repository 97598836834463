import cn from 'classnames';

import type {THRESHOLD_OPTIONS} from '@/components/brochureV2/layout/AutoGrid/AutoGrid';
import AutoGrid from '@/components/brochureV2/layout/AutoGrid/AutoGrid';
import Col, {Span, Start} from '@/components/brochureV2/layout/Grid/Col';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Button from '@/components/brochureV2/primitive/Button/Button';
import {
  ButtonVariant,
  getButtonTheme,
} from '@/components/brochureV2/primitive/Button/types';
import Card from '@/components/brochureV2/primitive/Card/Card';
import Link from '@/components/brochureV2/primitive/Link/Link';
import Section from '@/components/brochureV2/primitive/Section/Section';
import {AlignmentEnum, ButtonSizeEnum, ColorSchemeEnum} from '@/enums';
import type {CardComponent, LinkComponent} from '@/types';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';

import Heading from './Heading';

interface CardsProps {
  cards?: CardComponent[];
  colorScheme?: ColorSchemeEnum;
  linkSize?: ButtonSizeEnum;
  alignment?: AlignmentEnum;
  button?: LinkComponent;
  link?: LinkComponent;
  cardClassName?: string;
  componentName?: string;
  sectionName?: string;
  className?: string;
  heading?: string;
  headingClasses?: string;
  subhead?: string;
  threshold?: (typeof THRESHOLD_OPTIONS)[number];
}

/**
 * @deprecated
 */
export default function Cards({
  cards = [],
  cardClassName,
  className,
  colorScheme,
  linkSize,
  alignment,
  componentName = 'cards',
  button,
  link,
  heading,
  headingClasses,
  threshold = 3,
  ...props
}: CardsProps) {
  // @ts-ignore
  const gridThreshold: (typeof THRESHOLD_OPTIONS)[number] =
    cards?.length && cards.length === 2 ? cards.length : threshold;
  const currentAlignment = alignment || AlignmentEnum.Left;
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  const currentButtonTheme = getButtonTheme(currentScheme);
  const currentLinkSize = linkSize || ButtonSizeEnum.Medium;
  const filteredCards = cards?.filter((card) => !card.hidden);

  return (
    <Section
      sectionName="Cards"
      {...props}
      className={cn(className, sectionSchemeClasses[currentScheme])}
      componentName={componentName}
      data-component-name={componentName}
    >
      <Spacer size="2xl" className="top-spacer" />
      {heading && <Heading heading={heading} />}
      {filteredCards && filteredCards.length > 0 && (
        <AutoGrid
          threshold={alignment ? 3 : gridThreshold}
          className={cn('gap-y-xl container', {
            'justify-center': currentAlignment === AlignmentEnum.Center,
          })}
        >
          {filteredCards.map((card, index) => {
            const cardKey = `card-${card.heading}-${index}`;
            return (
              <Card
                key={cardKey}
                {...card}
                colorScheme={card.colorScheme || currentScheme}
                className={cardClassName || card.className}
                headingClasses={headingClasses}
              />
            );
          })}
        </AutoGrid>
      )}
      {(button || link) && (
        <Col
          className="text-center"
          start={{default: Start.One, sm: Start.Four, md: Start.Seven}}
          span={{default: Span.Four, sm: Span.Five, md: Span.Six}}
        >
          <Spacer size="2xl" />
          <div className="flex items-baseline justify-center">
            {button?.url && (
              <Button
                type="link"
                href={button.url}
                variant={ButtonVariant.Secondary}
                theme={currentButtonTheme}
                className={cn({'mr-4': link})}
                componentName={button.name || ''}
              >
                {button.text}
              </Button>
            )}
            {link?.url && (
              <Link
                href={link.url}
                theme={currentButtonTheme}
                size={currentLinkSize}
                componentName={link.name || ''}
              >
                {link.text}
              </Link>
            )}
          </div>
        </Col>
      )}
      <Spacer size="3xl" className="bottom-spacer" />
    </Section>
  );
}
